import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ClientService {
    private apiUrl = '';
    constructor(private httpService: HttpClient, private sharedService: SharedService) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    public getSurveyList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Clients/GetAll', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public addClient(client) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json-patch+json'
            })
        };
        const formData = JSON.stringify(client);
        return this.httpService.post(this.apiUrl + '/api/Clients/Post', formData, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public getClientSurveyList(uId): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get<any[]>(this.apiUrl + '/api/Clients/GetAllClientSurveys?uId=' + uId, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public UpdateClient(client) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json-patch+json'
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Clients/UpdateClient', client, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    // this method returns all client list
    // public getClientList() {
    //     const httpOptions = {
    //         headers: new HttpHeaders({
    //             'Authorization': 'Bearer ' + localStorage.getItem('token')
    //         })
    //     };
    //     return this.httpService.get(this.apiUrl + '/api/Clients/GetAll', httpOptions ).pipe(retry(1), catchError(this.handleError));
    // }

    public deleteClient(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Surveys/DeleteByUId?uId=' + id,{},
             httpOptions).pipe(retry(1), catchError(this.handleError));
    }
    // this method returns a particular client
    public getClient(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Clients/GetByUId?uId=' + id, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    public AssignSurveyToClient(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json-patch+json'
            })
        };
        let body = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Clients/AssignSurveyToClients', body, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
    public changePassword(data) {
        let body = JSON.stringify(data);
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json-patch+json'
            })
        };
        return this.httpService.post(this.apiUrl + '/api/accounts/ChangePassword', body, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public uploadLogo(file) {
        const httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          })
        };
        return this.httpService.post(this.apiUrl + '/api/Clients/UploadClientFile', file, httpOptions)
          .pipe(retry(1), catchError(this.handleError));
      }

      public getAllClient(obj): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post<any[]>(this.apiUrl + '/api/Clients/GetAllClient', obj, httpOptions )
            .pipe(retry(1), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }
}
